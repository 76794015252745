
<!--
 * @Author: your name
 * @Date: 2021-11-08 14:50:49
 * @LastEditTime: 2021-11-26 16:01:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /www/dtk_vue_cms_server_pc_v2/src/pages/ddq/index.vue
-->
<template>
  <div>
    <Loading :visible="loading" />
    <div class="ddq-banner">
      <img
        src="https://img.alicdn.com/imgextra/i2/2053469401/O1CN01ezaYDJ2JJi190Vf60_!!2053469401.png"
      />
    </div>
    <div class="ddq-nav" bi-route="tags">
      <div class="main" ref="ddqNavFixedRef" >
        <div class="row">
          <a class="paly-btn prev" @click="handlePalyPrev"></a>
          <div class="center" v-if="items.length > 0 ">
            <div class="scroll" :style="`width:${220*items.length}px; left:-${(index-2<0?0:index-2)*220}px;`">
              <a bi-click="time" :data-time="item.ddqTime" v-for="(item,key) in items " @click="handleScrollTab(item,key)" :key="item" :class="{active:key == index}">
                <b>{{renderTime(item.ddqTime)}}</b>
                <span>{{ddqConfig[item.status]?ddqConfig[item.status].name:''}}</span>
              </a>

              <p class="pr" :style="`left:${index * 220}px;`">

              </p>
            </div>
          </div>
          <a class="paly-btn next" @click="handlePalyNext"></a>
        </div>
      </div>
    </div>
    <div class="goods-main" bi-route="goods"> 
    <ul class="goods-list">
      <li bi-click="list" :data-index="index" :data-time="roundTime" :data-gid="item.id || item.goodsId"  v-for="(item, index) in gooodsItem" :key="item.id" >
        <!-- {{item}} -->
        <div>
          <div class="goods_pic" @click="handleShowPic(item)" v-marketgroup="item" >
            <img
              v-lazy="`${item.mainPic}_310x310.jpg`"
              alt
              style="background: rgb(245, 245, 245); display: inline;"
            />
          </div>
          <div class="goods_info">
            <div class="goods_tit_des">
              <h3>
                <a
                  :href="`?r=/l/d&amp;nav_wrap=ddq&amp;id=${item.id || item.goodsId}`"
                  target="_blank"
                >{{item.dtitle||item.title}}</a>
              </h3>
              <p @click="handleShowPic(item)" >{{(item.specialText||[]).join(',')}}</p>
            </div>
            <div class="goods_price_coupon" @click="handleShowPic(item)">
              <span class="goods_price">
                <b>
                  <i>￥</i>{{item.actualPrice}}
                </b>券后价
              </span>
              <span class="goods_price_old">￥{{item.originalPrice}}</span>
              <div class="goods_coupon">
                <span class="c_l c_coupon"></span>
                <span class="c_r c_coupon"></span>券 ￥{{item.couponPrice}}
              </div>
            </div>
            <div class="goods_gobuy_seller" @click="handleShowPic(item)">
              <span class="goods_seller fl">
                <i></i>
                <b>{{item.monthSales | salesNum(_,1)}}</b>人已抢
              </span>

              <span v-if="status==1" class="goods_buy_btn">马上抢</span>
              <span v-if="status==0" class="goods_buy_btn">马上抢</span>
              <span v-if="status==2" class="goods_buy_btn goods-soon">即将开始</span>
            </div>
          </div>
          
        </div>
      </li>
    </ul>
    </div>
    <div class="def-msg" v-if="status == 0">都是小编精心挑选的必抢好货哦~</div>
    <div class="def-msg" v-if="status == 1">都是小编精心挑选的必抢好货哦~</div>
    <div class="def-msg" v-if="status == 2">(ง •̀_•́)ง 亲！本场好货还在精心挑选中</div>
    <div class="ov-h" v-html="`<style>.search-fixed{
          display: none !important;
        }</style>`"></div>
    <MicroDetail @onClose="handleMicroDetailClose" v-if="visible" :visible="visible" :id="microDetailId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/loading";
import MicroDetail from '@/components/MicroDetail';
import {importCDN} from "@/utils/utils";
export default {
  data() {
    return {
      visible:false,
      microDetailId:'',
         loading: true,
      index:0,
      ddqConfig:{
        0:{name:'已开抢'},
        1:{name:'正在疯抢'},
        2:{name:'即将开始'}
      },
      items: [],
      gooodsItem:[],
      roundTime:'',
      status:0,
    };
  },
  components: {
    MicroDetail,
    Loading
  },
  computed: {
    ...mapGetters(["marketGroup", "config"])
  },
  methods:{
    handleShowPic(item){
      if (!this.config.micro_switch) {
        window.open(
          `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`,
          "_blank"
        );
      }else {
        this.microDetailId = item.id || item.goodsId;
        this.visible = true;
      }
    },
    handleMicroDetailClose(){
      this.visible = false;
    },
    scrollSearchFixed(){
        const {el,className} = window.ddqScroll;
        if(window.scrollY>375 && el){
          el.className = className + (className.indexOf('fixed') > -1 ? '' : ' fixed');
        }else if(el){
          el.className = className ;
        }
    },
    renderTime(time){
      const timeEvent = new Date(`${time}`.replace(/-/g,'/'));
      const hours = timeEvent.getHours();
      const minutes = timeEvent.getUTCMinutes();
      
      return `${hours < 10 ?`0${hours}`: hours }:${minutes < 10 ? `0${minutes}`: minutes }`;
    },
    handlePalyPrev(){
      // debugger
      this.index = this.index <= 0 ? this.items.length-1 : this.index -1;
      this.left = (this.items.length - 5) - (this.index - 2);
      this.roundTime = this.items[this.index].ddqTime;
      this.status = this.items[this.index].status;
      this.init(this.roundTime);
      
    },
    handlePalyNext(){
      this.index = this.index >= this.items.length-1 ? 0 : this.index + 1;
      this.roundTime = this.items[this.index].ddqTime;
      this.status = this.items[this.index].status;
      this.init(this.roundTime);
    },
    handleScrollTab(item,key){
      this.index = key;
      this.roundTime = item.ddqTime;
      this.status = item.status;
      
      this.init(item.ddqTime);
    },
    init(roundTime){
      this.loading = true
      if(window.scrollY > 376){
        window.scrollTo(0,0);
      }
      this.$services.getDTKOpenSuperCategory({roundTime}).then(res=>{
        this.loading = false
        if(res.code != 0) return false;
        this.items = res.data.roundsList;

        if(!roundTime){
          for(let i in res.data.roundsList){
            const data = res.data.roundsList[i];
            if(data.status == 1){
              this.status = 1;
              this.index = Number(i);
              this.roundTime = data.ddqTime;
            }
          }
        }
        

        this.gooodsItem  = res.data.goodsList;
        
      })
      

      document.addEventListener("scroll", this.scrollSearchFixed);
    }
  },
  destroyed() {
    document.removeEventListener("scroll",this.scrollSearchFixed,false);
  },
  mounted() {

    this.init();
    window.ddqScroll = {
      el:this.$refs.ddqNavFixedRef,
      className:this.$refs.ddqNavFixedRef.className,
    }
    if(this.config.site_name){
      document.title = `咚咚抢_${this.config.site_name}`;
    }
  }
};
</script>
<style scoped lang="less">
.ov-h{display: none;}
.ddq-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }
}
.ddq-nav {
  height: 50px;
  position: relative;
  z-index: 500;
  .main {
    width: 100%;
    height: 50px;
    background: #333;
    margin-bottom: 30px;
    color: #fff;
    
    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 50;
    }
    .row {
      width: 1200px;
      margin: auto;
      display: flex;
      .paly-btn {
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        background: #6e6e6e;
        cursor: pointer;
        position: relative;
        z-index: 5;
        &:hover {
          opacity: 0.9;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #fff;
          top: 50%;
          right: 50%;
          margin-right: -8px;
          margin-top: -10px;
        }
        &.next:after {
          border-width: 8px;
          border-left-color: #6e6e6e;
          margin-right: -6px;
          margin-top: -8px;
        }
        &.prev:after,
        &.prev:before {
          border-right: 10px solid #fff;
          border-left: none;
          right: 0;
          left: 50%;
          margin-left: -8px;
          margin-right: 0;
        }
        &.prev:after {
          border-right-color: #6e6e6e;
          margin-left: -6px;
        }
      }
      .center {
        width: 1100px;
        overflow: hidden;
        .scroll {
          display: flex;
          position: relative; z-index: 0;
          height: 60px;

            transition: all .3s ease;
          .pr{
            background: #a928f2;transition: all .3s ease;
            width: 220px;
            height: 50px;
            position: absolute; 
            top:0; left:0;
            z-index: -1;
            &::before{
              content: '';
              width: 0;
              height: 0;
              border-right: 7px solid transparent;
              border-left: 7px solid transparent;
              border-top: 7px solid #a928f2;
              position: absolute;
              left: 50%;
              bottom: -7px;
              margin-left: -7px
            }
          }
          a {
            width: 220px;
            line-height: 50px;
            display: block;
            padding-top: 5px;
            height: 50px;
            box-sizing: border-box;
            &.active:hover{
              background:none;
            }
            &:hover {
              background: #d07fff;
            }

            * {
              display: block;
              text-align: center;
              line-height: 20px;
              &.b {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.def-msg{
  clear: both; text-align: center; padding:20px 0 80px; color: #757575; font-size: 16px;
}
.goods-main{ width: 1200px;margin: auto; padding-top:30px;}
.goods-list {
    width: 1208px; padding-bottom: 20px;
    &::after{
      overflow: hidden; width: 0;height: 0; clear: both; content: ''; display: block;
    }
}

.goods-list li {
    overflow: hidden;
    float: left;
    width: 594px;
    margin: 0 8px 8px 0;
    height: 258px;
    background: #fff;
    border: 1px solid #eee
}

.goods-list li:hover {
    border-color: #DE2634
}

.goods-list li>div {
    display: block;
    width: 548px;
    height: 220px;
    padding: 19px 26px 19px 20px;
    margin: 0;
    position: relative;
    cursor: pointer
}

.goods-list li .goods_hdtype {
    position: absolute;
    width: 60px;
    height: 60px;
    background: url(./images/cms_pc_img.png?v=0927?v=2021105203122) -170px -122px no-repeat;
    top: 10px;
    left: 10px;
    z-index: 1
}

.goods-list li .goods_hdtype.goods_hdtype_zhe {
    background-position-x: -242px
}

.goods-list li .goods_pic {
    width: 220px;
    height: 220px;
    float: left;
    overflow: hidden;
    position: relative
}

.goods-list li .goods_pic img {
    max-width: 220px;
    max-height: 220px;
    vertical-align: top
}

.gl-img,.swiper-slide {
    vertical-align: middle
}

.goods-list li .goods_info {
    float: right;
    width: 310px;
    overflow: hidden
}
.goods-list li .goods_info a{
  color: #333;
}
.goods-list li .goods_info .goods_tit_des {
    color: #DE2634;
    margin-bottom: 40px
}

.goods-list li .goods_info .goods_tit_des h3 {
    margin: 0;
    padding: 0;
    height: 36px;
    font-size: 16px;
    line-height: 36px;
    color: #333;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.goods-list li .goods_info .goods_tit_des p {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    overflow: hidden
}

.goods-list li .goods_info .goods_price_coupon {
    height: 20px;
    position: relative;
    margin-bottom: 30px;
    line-height: 16px;
    color: #999
}

.goods-list li .goods_info .goods_price_coupon .goods_price {
    float: left;
    margin-right: 30px;
    font-size: 12px
}

.goods-list li .goods_info .goods_price_coupon .goods_price b i {
    font-size: 14px;
    font-weight: 400;
    position: relative
}

.goods-list li .goods_info .goods_price_coupon .goods_price b {
    color: #DE2634;
    font-size: 26px;
    position: relative;
    margin-right: 5px;
    top: 1px
}

.goods-list li .goods_info .goods_price_coupon .goods_price_old {
    float: left;
    line-height: 26px;
    text-decoration: line-through
}

.goods_coupon {
    width: 60px;
    height: 20px;
    margin-left: 1px;
    right: 0;
    float: left;
    line-height: 20px;
    font-size: .8em;
    color: #FF7800;
    background-image: url(./images/quanbg.png?v=2021105203122);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
}

.goods_coupon.jks {
    background-image: url(./images/quanbg1.png?v=2021105203122);
    color: #2AC064
}

.goods-list li .goods_info .goods_price_coupon .goods_coupon {
    float: right
}

.gl-content,.gl-img,.yhq,.zsz {
    float: left
}

.goods-list li .goods_info .goods_gobuy_seller {
    position: relative;
    border: 1px solid #F3C7C7;
    width: 308px;
    height: 38px
}

.goods-list li .goods_info .goods_gobuy_seller.goods_gobuy_seller_soon {
    border: 1px solid #fff
}

.goods-list li .goods_info .goods_gobuy_seller.goods_gobuy_seller_soon .goods_seller {
    display: none
}

.goods-list li .goods_info .goods_gobuy_seller .goods_seller {
    margin: 12px 120px 12px 15px;
    display: block;
    height: 14px;
    line-height: 14px;
    color: #aaa
}

.goods-list li .goods_info .goods_gobuy_seller .goods_seller i {
    float: left;
    width: 12px;
    position: relative;
    height: 16px;
    top: -2px;
    background: url(./images/cms_pc_img.png?v=1014?v=2021105203122) -173px -322px no-repeat
}

.goods-list li .goods_info .goods_gobuy_seller .goods_seller b {
    color: #FF9960;
    font-weight: 400;
    margin: 0 5px
}

.goods-list li .goods_info .goods_gobuy_seller .goods_buy_btn {
    position: absolute;
    right: -1px;
    width: 120px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    height: 40px;
    font-size: 18px;
    background: #DE2634;
    top: -1px;
    z-index: 2
}

.goods-list li .goods_info .goods_gobuy_seller .goods_buy_btn.goods-soon {
    background: #cfbbeb
}

.goods-list li .goods_info .goods_gobuy_seller .goods_buy_btn.goods-over {
    background: #ccc
}

</style>