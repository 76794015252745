var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _vm._m(0),
      _c("div", { staticClass: "ddq-nav", attrs: { "bi-route": "tags" } }, [
        _c("div", { ref: "ddqNavFixedRef", staticClass: "main" }, [
          _c("div", { staticClass: "row" }, [
            _c("a", {
              staticClass: "paly-btn prev",
              on: { click: _vm.handlePalyPrev }
            }),
            _vm.items.length > 0
              ? _c("div", { staticClass: "center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "scroll",
                      style:
                        "width:" +
                        220 * _vm.items.length +
                        "px; left:-" +
                        (_vm.index - 2 < 0 ? 0 : _vm.index - 2) * 220 +
                        "px;"
                    },
                    [
                      _vm._l(_vm.items, function(item, key) {
                        return _c(
                          "a",
                          {
                            key: item,
                            class: { active: key == _vm.index },
                            attrs: {
                              "bi-click": "time",
                              "data-time": item.ddqTime
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleScrollTab(item, key)
                              }
                            }
                          },
                          [
                            _c("b", [
                              _vm._v(_vm._s(_vm.renderTime(item.ddqTime)))
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.ddqConfig[item.status]
                                    ? _vm.ddqConfig[item.status].name
                                    : ""
                                )
                              )
                            ])
                          ]
                        )
                      }),
                      _c("p", {
                        staticClass: "pr",
                        style: "left:" + _vm.index * 220 + "px;"
                      })
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _c("a", {
              staticClass: "paly-btn next",
              on: { click: _vm.handlePalyNext }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "goods-main", attrs: { "bi-route": "goods" } }, [
        _c(
          "ul",
          { staticClass: "goods-list" },
          _vm._l(_vm.gooodsItem, function(item, index) {
            return _c(
              "li",
              {
                key: item.id,
                attrs: {
                  "bi-click": "list",
                  "data-index": index,
                  "data-time": _vm.roundTime,
                  "data-gid": item.id || item.goodsId
                }
              },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "marketgroup",
                          rawName: "v-marketgroup",
                          value: item,
                          expression: "item"
                        }
                      ],
                      staticClass: "goods_pic",
                      on: {
                        click: function($event) {
                          return _vm.handleShowPic(item)
                        }
                      }
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: item.mainPic + "_310x310.jpg",
                            expression: "`${item.mainPic}_310x310.jpg`"
                          }
                        ],
                        staticStyle: {
                          background: "rgb(245, 245, 245)",
                          display: "inline"
                        },
                        attrs: { alt: "" }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "goods_info" }, [
                    _c("div", { staticClass: "goods_tit_des" }, [
                      _c("h3", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "?r=/l/d&nav_wrap=ddq&id=" +
                                (item.id || item.goodsId),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.dtitle || item.title))]
                        )
                      ]),
                      _c(
                        "p",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleShowPic(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s((item.specialText || []).join(",")))]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "goods_price_coupon",
                        on: {
                          click: function($event) {
                            return _vm.handleShowPic(item)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "goods_price" }, [
                          _c("b", [
                            _c("i", [_vm._v("￥")]),
                            _vm._v(_vm._s(item.actualPrice) + " ")
                          ]),
                          _vm._v("券后价 ")
                        ]),
                        _c("span", { staticClass: "goods_price_old" }, [
                          _vm._v("￥" + _vm._s(item.originalPrice))
                        ]),
                        _c("div", { staticClass: "goods_coupon" }, [
                          _c("span", { staticClass: "c_l c_coupon" }),
                          _c("span", { staticClass: "c_r c_coupon" }),
                          _vm._v("券 ￥" + _vm._s(item.couponPrice) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "goods_gobuy_seller",
                        on: {
                          click: function($event) {
                            return _vm.handleShowPic(item)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "goods_seller fl" }, [
                          _c("i"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("salesNum")(item.monthSales, _vm._, 1)
                              )
                            )
                          ]),
                          _vm._v("人已抢 ")
                        ]),
                        _vm.status == 1
                          ? _c("span", { staticClass: "goods_buy_btn" }, [
                              _vm._v("马上抢")
                            ])
                          : _vm._e(),
                        _vm.status == 0
                          ? _c("span", { staticClass: "goods_buy_btn" }, [
                              _vm._v("马上抢")
                            ])
                          : _vm._e(),
                        _vm.status == 2
                          ? _c(
                              "span",
                              { staticClass: "goods_buy_btn goods-soon" },
                              [_vm._v("即将开始")]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm.status == 0
        ? _c("div", { staticClass: "def-msg" }, [
            _vm._v("都是小编精心挑选的必抢好货哦~")
          ])
        : _vm._e(),
      _vm.status == 1
        ? _c("div", { staticClass: "def-msg" }, [
            _vm._v("都是小编精心挑选的必抢好货哦~")
          ])
        : _vm._e(),
      _vm.status == 2
        ? _c("div", { staticClass: "def-msg" }, [
            _vm._v("(ง •̀_•́)ง 亲！本场好货还在精心挑选中")
          ])
        : _vm._e(),
      _c("div", {
        staticClass: "ov-h",
        domProps: {
          innerHTML: _vm._s(
            "<style>.search-fixed{\n        display: none !important;\n      }</style>"
          )
        }
      }),
      _vm.visible
        ? _c("MicroDetail", {
            attrs: { visible: _vm.visible, id: _vm.microDetailId },
            on: { onClose: _vm.handleMicroDetailClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ddq-banner" }, [
      _c("img", {
        attrs: {
          src:
            "https://img.alicdn.com/imgextra/i2/2053469401/O1CN01ezaYDJ2JJi190Vf60_!!2053469401.png"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }